import tw, { styled } from "twin.macro"

import { StyledButton } from "../../styled/Button"
import { H1, P } from "../../styled/Text"

export { StyledContainer as Container } from "../../styled/Container"

export const Section = styled.div`
  ${tw`text-center my-12 px-4 md:px-0`}
`
export const Title = styled(H1)`
  ${tw`mb-4 font-bold lg:text-2xl xl:text-3xl`}
  ${({ large }) => (large ? tw`-mt-4 md:mt-0` : tw`text-lg`)}
`
export const Content = tw(P)`w-full md:px-4`
export const Button = tw(StyledButton)`block w-full md:w-auto md:inline-block mt-8 text-center`
export const Emoji = tw.p`text-12xl text-center w-full leading-none`
export const Description = tw(P)`text-grey-mid max-w-sm mx-auto md:max-w-none md:mx-0 mb-12`
