import React from "react"
import { Link } from "gatsby"

import { withContact } from "./withContact"
import { ContactForm } from "./ContactForm"
import { Container, Title, Content, Button, Emoji, Description, Section } from "./Contact.styled"

export const Contact = withContact(
  ({ title, content, sent, handleSent }): JSX.Element => (
    <Section>
      <Container condense={`<md`} width={`md`}>
        {sent ? (
          <>
            <Title large>Thanks for your enquiry</Title>
            <Description>We&apos;ll get back to you soon!</Description>
            <Emoji>🙏</Emoji>
            <Button as={Link} to={`/account/dashboard`} size={`large`} theme={`primary`} colour={`dark`}>
              Back to my dashboard
            </Button>
          </>
        ) : (
          <>
            <Title large>{title}</Title>
            <Content>{content}</Content>
            <ContactForm handleSent={handleSent} />
          </>
        )}
      </Container>
    </Section>
  )
)

export interface Props {
  sent?: boolean
  handleSent?: (boolean) => void
}
