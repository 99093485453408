import React, { useMemo } from "react"

import { useForm } from "../../hooks/useForm"
import { useApp } from "../../hooks/useApp"
import { useKlaviyo } from "../../hooks/useKlaviyo"

export const withContactForm = Component =>
  React.memo(({ name = "ContactForm", handleSent }) => {
    const { customer } = useApp()
    const { getForm, submitForm, data, setData, loading, errors } = useForm(customer ? { email: customer?.email, name: customer?.firstName } : {})
    const { trackEvent } = useKlaviyo()
    const form = getForm("contact")

    const type = data?.type ? form?.types?.[data?.type?.toLowerCase()] : Object?.values(form?.types)[0]
    const options = type?.options

    const handleSubmit = async event => {
      event.preventDefault()
      await submitForm(form, { ...data, type })
      trackEvent("Completed Form Contact", data)
      handleSent(true)
    }

    const handleChange = ({ target: { type, name, value, checked }, target }) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
    }

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          data={data}
          options={options}
          form={form}
          customer={customer}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          errors={errors}
        />
      ),
      [customer, data, loading, errors]
    )
  })
