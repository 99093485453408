import React from "react"

import { withContactForm } from "./withContactForm"
import { Form, Textarea, Frame, Title, Description, Field, Button, ButtonWrapper } from "../Form/Form.styled"

export const ContactForm = withContactForm(
  ({ form, options, customer, data, handleChange, handleSubmit, loading, errors }): JSX.Element => (
    <Frame condensed>
      {errors?.length > 0 && (
        <Description>
          <ul>
            {errors?.map((error, index) => (
              <li key={index}>
                <p>{error?.message}</p>
              </li>
            ))}
          </ul>
        </Description>
      )}
      <br />
      <Form onSubmit={handleSubmit}>
        <Field
          name={"name"}
          type={"text"}
          placeholder={"Name"}
          value={data?.name}
          onChange={handleChange}
          required={data?.name?.length > 0}
          autoFocus
          condensed
          error={errors?.filter(error => error?.field?.includes("firstName") || error?.field?.includes("lastName"))?.length}
        />
        <Field
          name={"email"}
          type={"email"}
          placeholder={"Email"}
          value={data?.email}
          onChange={handleChange}
          required
          condensed
          error={errors?.filter(error => error?.field?.includes("email"))?.length}
        />
        <Field as={Textarea} name={"message"} type={"textarea"} placeholder={"Message"} value={data?.message} onChange={handleChange} required />
        <ButtonWrapper condensed center>
          <Button
            size={`large`}
            colour={`dark`}
            theme={`primary`}
            loading={loading}
            disabled={data?.email?.length < 6 || data?.name?.length < 4 || data?.message?.length < 8}
          >
            {loading ? "Sending..." : "Send"}
          </Button>
        </ButtonWrapper>
      </Form>
    </Frame>
  )
)
