import React, { useState, useMemo } from "react"

import { useCore } from "../../hooks/useCore"

export const withContact = Component =>
  React.memo(({ name = "Contact", page }) => {
    const {
      helpers: { sanityContent },
    } = useCore()

    const [sent, setSent] = useState(false)

    const title = page?.title
    const content = sanityContent(page?.content)

    Component.displayName = name
    return useMemo(() => <Component title={title} content={content} sent={sent} handleSent={setSent} />, [sent])
  })
